















































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uOttawa1321Prelab2Q1',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        meltingPointProportionalTo: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {text: 'The number of carbon atoms', value: 'numCarbonAtoms'},
        {text: 'The number of functional groups', value: 'numFunctionalGroups'},
        {text: 'The size of the molecule', value: 'size'},
        {text: 'The number and type of possible intermolecular interactions', value: 'IMFs'},
        {text: 'The specific heat capacity of the compound', value: 'specificHeat'},
      ],
      optionsFr: [
        {text: "Le nombre d'atomes de carbon", value: 'numCarbonAtomsFr'},
        {text: 'Le nombre de groupements fonctionnels', value: 'numFunctionalGroupsFr'},
        {text: 'La taille de la molécule', value: 'sizeFr'},
        {
          text: 'Le nombre et la type des intéractions intermoléculaires possibles',
          value: 'IMFs',
        },
        {text: 'La capacité thermique massique du composé', value: 'specificHeatFr'},
      ],
    };
  },
};
